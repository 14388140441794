import { Drawer, IconButton } from '@mui/material';
import { StoryblokLink } from '@/types/StoryblokLink';
import cross from '../../assets/cross.svg';
import { StoryblokRichtext, render } from 'storyblok-rich-text-react-renderer';
import Link from '../base/Link';

import { FC } from 'react';

type OverlayContentProps = {
  title: string;
  content: StoryblokRichtext;
  link_text?: string;
  link?: StoryblokLink;
  onClose: () => void;
};

const OverlayContent: FC<OverlayContentProps> = ({
  title,
  content,
  link_text,
  link,
  onClose,
}) => {
  return (
    <Drawer
      anchor='left'
      open={true}
      onClose={onClose}
      sx={{
        '& .MuiDrawer-paper': {
          height: '100vh',
          width: '100vw',
          backgroundColor: 'rgba(0, 0, 0, 0.85)',
          position: 'relative', // important !
          '@media (min-width: 1120px)': {
            width: '80vw',
          },
        },
      }}
    >
      <div className='relative w-full h-full overflow-y-auto'>
        <div className='absolute top-[3px] tablet:top-[34px] right-2 tablet:right-6 z-50'>
          <IconButton onClick={onClose}>
            <img
              src={cross}
              alt='cross'
              className='h-[22px] w-[22px] tablet:h-[36px] tablet:w-[36px]'
            />
          </IconButton>
        </div>

        <span className='vertical-rl fixed top-[42px] left-[52px] z-30 text-white hidden desktop:inline text-[16px] font-bold leading-[0.8]'>
          {title}
        </span>

        <span className='vertical-rl fixed top-1/3 right-4 z-30 text-white text-[12px] tablet:text-[14px] font-bold leading-[0.8] desktop:hidden'>
          {title}
        </span>

        {/* Content */}
        <div className='relative  flex flex-col text-white text-left max-w-[650px] mt-20 ml-4 mr-10 tablet:m-10 tablet:mt-28 desktop:m-40 z-20'>
          <h2 className='font-bold text-[32px] tablet:text-[40px] leading-[0.8] mb-8'>
            {title}
          </h2>

          <div className='style-1-mobile tablet:style-1'>{render(content)}</div>

          <div className='mt-1 mb-8'>
            <Link
              href={link?.cached_url}
              target='_blank'
              label={link_text}
              hoverColor='white'
              textColor='#A994FF'
              darkmode={true}
            />
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default OverlayContent;
