import { useState } from 'react';
import Link from '../base/Link';
import OverlayContent from '../base/OverlayContent';
import { StoryblokLink } from '@/types/StoryblokLink';
import { StoryblokRichtext } from 'storyblok-rich-text-react-renderer';

type OverlayTriggerProps = {
  blok: {
    headline: string;
    copy: string;
    button_text: string;
    overlay_title: string;
    overlay_content: StoryblokRichtext;
    overlay_link_text: string;
    overlay_link: StoryblokLink;
  };
};

export const OverlayTrigger = ({ blok }: OverlayTriggerProps) => {
  const [open, setOpen] = useState(false);

  const cleanedHeadline = blok.headline.trim().replace(/\s+/g, ' ');
  const words = cleanedHeadline.split(' ');

  return (
    <>
      <div className='bg-flieder-soft-background grid tablet:grid-cols-[2fr_3fr] gap-8 py-[110px]'>
        <div className='grid grid-rows-1 justify-start pl-5 bg-body text-[80px] tablet:text-[100px] font-soehne-schmal break-words leading-none tablet:justify-end tablet:text-right uppercase py-[25px] pr-5'>
          <h1 className='leading-[0.8] max-w-container'>
            <div>
              <span className='text-flieder'>{words[0]}</span>
            </div>
            <div>
              {words[1]} {words[2]}
            </div>
            <div>{words.slice(3).join(' ')}</div>
          </h1>
        </div>

        <div className='grid bg-body p-5 text-[16px] tablet:text-[18px]'>
          <p className='max-w-[650px] font-body mb-1'>{blok.copy}</p>
          <Link
            label={blok.button_text}
            hoverColor='black'
            textColor='#A994FF'
            onClick={() => setOpen(true)}
          />
        </div>
      </div>
      {open && (
        <OverlayContent
          title={blok.overlay_title}
          content={blok.overlay_content}
          link_text={blok.overlay_link_text}
          link={blok.overlay_link}
          onClose={() => setOpen(false)}
        ></OverlayContent>
      )}{' '}
    </>
  );
};
